var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c(
      "div",
      { staticClass: "container-fluid main" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.t("footer.title1")))]),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.t("footer.title2")) } }),
        _vm._v(" "),
        _c("Apps"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sub" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 col-lg-2" }, [
            _c(
              "ul",
              [
                _c("h4", [_vm._v(_vm._s(_vm.t("footer.service")))]),
                _vm._v(" "),
                _vm._l(_vm.$store.state.layout.menu, function (menu, index) {
                  return _c(
                    "router-link",
                    { key: index, attrs: { to: menu.path } },
                    [
                      _c("li", [
                        _vm._v(
                          _vm._s(_vm.t("menu." + menu.path.replace("/", "")))
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 col-lg-2" }, [
            _c(
              "ul",
              [
                _c("h4", [_vm._v(_vm._s(_vm.t("footer.about")))]),
                _vm._v(" "),
                _vm._l(_vm.links, function (item, index) {
                  return _c(
                    "router-link",
                    { key: index, attrs: { to: item.link } },
                    [_c("li", [_vm._v(_vm._s(_vm.t("footer." + item.key)))])]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6 col-md-6 col-xs-12" },
            [_c("Apps")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-6 col-md-6 col-xs-12 align-self-center social-link",
            },
            _vm._l(_vm.socialLinks, function (item, index) {
              return _c(
                "a",
                { key: index, attrs: { href: item.link, target: "_blank" } },
                [_c("img", { attrs: { src: item.imgUrl, alt: item.alt } })]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info row" }, [
          _c("div", { staticClass: "col-lg-11" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.t("footer.info")) + "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "copyright" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.t("footer.copyright", { year: new Date().getFullYear() })
              ) +
              "\n      "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }