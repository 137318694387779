var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container", attrs: { id: "error-page" } }, [
    _vm.error.statusCode === 404
      ? _c("div", { staticClass: "error-content" }, [
          _c("h2", [_vm._v(_vm._s(_vm.t("error.oops")))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.t("error.apologise_not_exist")))]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm._v("\n        " + _vm._s(_vm.t("error.tips")) + "\n        "),
              _c("p", [
                _c("a", { attrs: { href: "/" } }, [
                  _vm._v(_vm._s(_vm.t("error.go_homepage"))),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "error-content" }, [
          _c("h2", [
            _vm._v("\n      " + _vm._s(_vm.t("error.title")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.t("error.desc")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.t("error.tips")))]),
          _vm._v(" "),
          _c("p", [
            _c("a", { attrs: { href: "/" } }, [
              _vm._v(_vm._s(_vm.t("error.go_homepage"))),
            ]),
            _vm._v("."),
          ]),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.t("error.contact")) } }),
          _c("p"),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-md-4" }, [
              _c("strong", [_vm._v(_vm._s(_vm.t("error.uri")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-md-8" }, [
              _c("a", { attrs: { href: _vm.url } }, [_vm._v(_vm._s(_vm.url))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col col-md-4" }, [
                _c("strong", [_vm._v(_vm._s(_vm.t("error.message")))]),
              ]),
              _vm._v(" "),
              _c("no-ssr", [
                _c("div", { staticClass: "col col-md-8" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.error.statusCode) +
                      " " +
                      _vm._s(_vm.error.message || "Server Error") +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }