var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "modal apply-modal fade",
        attrs: {
          id: "apply-modal",
          tabindex: "-1",
          "aria-labelledby": "applyModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-autoheight",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-content" }, [
              _c("button", {
                staticClass: "modal-btn-close",
                attrs: { "data-dismiss": "modal", type: "button" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "apply-modal-details-content" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.t("contact.form_title")))]),
                  _vm._v(" "),
                  _c("label", [_vm._v(_vm._s(_vm.t("contact.require_info")))]),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { id: "award-form", autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.formSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.t("contact.email")))]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "email",
                        staticClass: "form-control",
                        attrs: {
                          fieldTitle: _vm.t("contact.email", {}, "zh"),
                          type: "email",
                          maxlength: "200",
                          autocomplete: "off",
                          fieldname: "email",
                          fieldType: "unique",
                          required: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(_vm.t("contact.eng_name")))]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "name",
                        staticClass: "form-control",
                        attrs: {
                          fieldTitle: _vm.t("contact.eng_name", {}, "zh"),
                          type: "input",
                          maxlength: "200",
                          fieldname: "fullname",
                          fieldType: "text",
                          required: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(_vm.t("contact.ref")))]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "identity",
                        staticClass: "form-control",
                        attrs: {
                          fieldTitle: _vm.t("contact.ref", {}, "zh"),
                          type: "input",
                          fieldname: "referenceNo",
                          fieldType: "text",
                          maxlength: "16",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(_vm.t("contact.phone")))]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "phone",
                        staticClass: "form-control",
                        attrs: {
                          fieldTitle: _vm.t("contact.phone", {}, "zh"),
                          type: "phone",
                          fieldname: "mobile",
                          fieldType: "mobile",
                          maxlength: "14",
                          required: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(_vm._s(_vm.t("contact.org_service"))),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "service",
                        staticClass: "form-control",
                        attrs: {
                          id: "service-txt",
                          fieldTitle: _vm.t("contact.org_service", {}, "zh"),
                          fieldname: "organization",
                          fieldType: "text",
                          role: "presentation",
                          type: "text",
                          autoComplete: "off",
                          maxlength: "200",
                          required: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center pt-3" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "rounded-pill w-25 text-center bg-teal text-white border-0 lh-xs-30 fs-xs-20 btn-secondary",
                            class: _vm.loading ? "disabled" : "",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm.loading
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.t("contact.sending"))),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.t("contact.done"))),
                                ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "modal-btn-close",
        attrs: { type: "button", "data-dismiss": "modal" },
      },
      [_c("img", { attrs: { src: "/img/icons/icon-close.png", alt: "close" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }