var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.page ? `page-${_vm.page}` : "", "lang-" + _vm.lang] },
    [_c("Header"), _vm._v(" "), _c("Nuxt"), _vm._v(" "), _c("Footer")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }