var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apps" },
    _vm._l(_vm.apps, function (app, index) {
      return _c("li", { key: index }, [
        _c("a", { attrs: { href: app.link, target: "blank" } }, [
          _c("img", { attrs: { src: app.imgUrl } }),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }