var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "relative" },
          [
            _c("div", { staticClass: "header-logo col-lg-2" }, [
              _c("a", { staticClass: "d-inline-flex", attrs: { href: "/" } }, [
                _c("img", { attrs: { src: _vm.logoUrl, alt: "Money Plaza" } }),
              ]),
            ]),
            _vm._v(" "),
            _c("no-ssr", [
              _vm.isMobile
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-download",
                      attrs: { href: _vm.appSource, target: "_blank" },
                    },
                    [_vm._v(_vm._s(_vm.t("menu.download")))]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.clickOutside,
                    expression: "clickOutside",
                  },
                ],
                staticClass: "nav",
              },
              [
                _c("i", {
                  on: {
                    click: function ($event) {
                      return _vm.toggleMenu(true)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  [
                    _c(
                      "a",
                      { staticClass: "mobile-only", attrs: { href: "/" } },
                      [
                        _c("img", {
                          attrs: { src: _vm.logoUrl, alt: "Money Plaza" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "main-menu",
                        class:
                          "items-" + (_vm.$store.state.layout.menu.length - 1),
                      },
                      _vm._l(
                        _vm.$store.state.layout.menu,
                        function (menu, index) {
                          return menu.path !== "/blog"
                            ? _c(
                                "router-link",
                                { key: index, attrs: { to: menu.path } },
                                [
                                  _c(
                                    "li",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleMenu(false)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.t(
                                              "menu." +
                                                menu.path.replace("/", "")
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }
                      ),
                      1
                    ),
                    _vm._v(" "),
                    _c("router-link", { attrs: { to: "/blog" } }, [
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toggleMenu(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("menu.blog")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("router-link", { attrs: { to: "/query" } }, [
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toggleMenu(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("common.qa")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right-button" },
                      [
                        _c(
                          "client-only",
                          [
                            !_vm.isAuth
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "apply-form-btn",
                                    attrs: { href: "javascript:void(0)" },
                                    on: { click: _vm.showFormModal },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.t("menu.form")) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isAuth
                              ? _c("div", { staticClass: "dropdown" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn dropdown-toggle",
                                      attrs: {
                                        id: "dropdownMenuButton",
                                        type: "button",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Hi! " + _vm._s(_vm.username)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-right",
                                      attrs: {
                                        "aria-labelledby": "dropdownMenuButton",
                                      },
                                    },
                                    [
                                      _c("h5", [
                                        _vm._v(_vm._s(_vm.t("profile.info"))),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: "/profile", exact: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.t("profile.basic")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: "/profile/contact" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.t("profile.contact")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: "/profile/requirement" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.t("profile.requirement")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: "/profile/loan_status" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.t("profile.loan_status")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: "/profile/reward" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.t("profile.reward")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: "/profile/resetpassword",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.t("profile.change_password")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "dropdown-divider",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "nuxt-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: "/logout" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.t("login.logout")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _c(
                                  "nuxt-link",
                                  {
                                    staticClass: "login-btn",
                                    attrs: { to: "/login" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      { staticClass: "btn btn-secondary" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.t("menu.login")) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn-group globe-list" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn dropdown-toggle",
                          attrs: {
                            type: "button",
                            "data-toggle": "dropdown",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-globe" }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.locale))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-right" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.setLocale("zh")
                                },
                              },
                            },
                            [_vm._v("\n                繁體\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.setLocale("en")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                English\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("ApplyModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }